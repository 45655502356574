
import { apiMessageLists, apiMessageDel } from '@/api/shop'
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'

@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog
  }
})
export default class ShopList extends Vue {
  /** S Data **/

  apiMessageLists = apiMessageLists;

  pager: RequestPaging = new RequestPaging();
  pager2: RequestPaging = new RequestPaging();
  pager3: RequestPaging = new RequestPaging();

  searchObj: Object = {
    
  };

  /** E Data **/

  /** S Methods **/

  // 获取工厂列表
  getShopLists (): void {
    this.pager.request({
      callback: apiMessageLists,
      params: {
        ...this.searchObj
      }
    })
  }

  // 删除商城
  async onShopDelete (row: any): Promise<void> {
    await apiMessageDel({ id: row.id })
    this.getShopLists()
  }

  // 重置搜索
  reset (): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, '')
    })
    this.getShopLists()
  }

  // 去编辑
  toShopEdit (id: number | any): void {
    this.$router.push({
      path: '/shop/message_list_detail',
      query: {
        id: id as any,
        mode: PageMode.EDIT
      }
    })
  }

  /** E Methods **/

  /** S ods **/
  created () {
    this.getShopLists()
  }
}
